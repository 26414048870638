<template>
  <el-tooltip class="test-test" placement="top" effect="dark">
    <template #content>
      {{ $t('priceChangeImplications.beforeChange', { amount: fromPriceText, date: fromDateText }) }}
      <br />
      {{ $t('priceChangeImplications.afterChange', { amount: toPriceText, date: toDateText }) }}
    </template>
    <div class="price-change">
      <div class="price price-before">{{ fromPriceText }}</div>
      <div class="price-change-arrow">
        <ArrowLeftIcon height="16px" width="16px" fill="#71767D" :class="{ 'icon-mirror': ltr }" />
      </div>
      <div class="price">{{ toPriceText }}</div>
    </div>
  </el-tooltip>
</template>

<script>
import { DateTime } from 'luxon';
import { computed } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { ArrowLeftIcon } from '@/assets/icons/';

import { formatCurrencyOrDash } from '@/modules/purchase-management/purchaseManagementFormatters';

export default {
  components: { ArrowLeftIcon },
  props: {
    fromPrice: { type: Number, required: true },
    fromDate: { type: String, required: true },
    toPrice: { type: Number, required: true },
    toDate: { type: String, required: true },
    ltr: { type: Boolean, default: false },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    const fromPriceText = computed(() => formatCurrencyOrDash(props.fromPrice, currencyFormat.value));
    const toPriceText = computed(() => formatCurrencyOrDash(props.toPrice, currencyFormat.value));
    const fromDateText = computed(() => DateTime.fromISO(props.fromDate).toLocaleString());
    const toDateText = computed(() => DateTime.fromISO(props.toDate).toLocaleString());

    return {
      fromPriceText,
      toPriceText,
      toDateText,
      fromDateText,
    };
  },
};
</script>

<style scoped lang="scss">
.price-change {
  display: flex;
  align-items: center;
  .price-change-arrow {
    padding-left: 5px;
    padding-right: 5px;
  }
  .icon-mirror {
    transform: rotate(180deg);
  }
}
</style>
