<template>
  <div class="yearly-estimated-impact">
    <div class="estimated-amount" :class="{ 'total-amount': isTotal }">
      <div>
        <div class="delta-arrow">
          <ArrowTopRightIcon
            v-if="estimatedAmount != 0 && isPositiveEstimate"
            :size="deltaArrowSize"
            :fill="'#E52044'"
          />
          <ArrowBottomRightIcon
            v-if="estimatedAmount != 0 && !isPositiveEstimate"
            :size="deltaArrowSize"
            :fill="'#11874F'"
          />
          <MinusIcon v-if="estimatedAmount == 0" :size="deltaArrowSize" :fill="'#71767d'" />
        </div>

        {{ estimatedAmountFormatted }}
      </div>
    </div>
    <div class="estimated-impact-title">{{ title }}</div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { ArrowTopRightIcon, ArrowBottomRightIcon, MinusIcon } from '@/assets/icons/';

export default {
  components: {
    ArrowTopRightIcon,
    ArrowBottomRightIcon,
    MinusIcon,
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    estimatedAmount: {
      type: Number,
      required: true,
      default: 0,
    },
    isTotal: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { currencyFormat } = useCurrency();

    const { locale } = root.$i18n;

    const numberFormatter = computed(
      () =>
        new Intl.NumberFormat(locale, {
          ...currencyFormat.value,
          maximumFractionDigits: 0,
          roundingIncrement: 100,
        })
    );

    const estimatedAmountFormatted = computed(() => {
      return numberFormatter.value.format(props.estimatedAmount);
    });
    const isPositiveEstimate = computed(() => props.estimatedAmount > 0);
    const deltaArrowSize = computed(() => (props.isTotal ? 24 : 16));
    return {
      estimatedAmountFormatted,
      isPositiveEstimate,
      deltaArrowSize,
    };
  },
};
</script>

<style scoped lang="scss">
.yearly-estimated-impact {
  .estimated-impact-title {
    color: #71767d;
    font-size: 0.75rem;
  }
  .estimated-amount {
    font-size: 1.25rem;
    font-weight: 450;
    display: flex;
    align-items: center;
  }
  .total-amount {
    font-weight: 500;
    font-size: 1.25rem;
  }
}
</style>
