import i18n from '@/imports/startup/client/i18n';

import { PRICE_IMPLICATIONS_HEADERS } from './priceChangeImplicationsConstants';
import { EXCEL_TYPES } from '../../excel';

export default (activeSort) => {
  return [
    {
      header: i18n.t('priceChangeImplications.productSku'),
      key: PRICE_IMPLICATIONS_HEADERS.SKU,
      width: `100px`,
      filterActive: true,
    },
    {
      header: i18n.t('priceChangeImplications.productName'),
      key: PRICE_IMPLICATIONS_HEADERS.NAME,
      filterActive: true,
      sortCallback: (direction) => (activeSort.value = { direction, columnKey: PRICE_IMPLICATIONS_HEADERS.NAME }),
    },
    {
      header: i18n.t('priceChangeImplications.productSupplierName'),
      key: PRICE_IMPLICATIONS_HEADERS.SUPPLIER_NAME,
      width: `150px`,
      filterActive: true,
      sortCallback: (direction) =>
        (activeSort.value = { direction, columnKey: PRICE_IMPLICATIONS_HEADERS.SUPPLIER_NAME }),
    },
    {
      header: i18n.t('priceChangeImplications.priceChange'),
      key: PRICE_IMPLICATIONS_HEADERS.PRICE_CHANGE,
    },
    {
      header: i18n.t('priceChangeImplications.percentageChange'),
      key: PRICE_IMPLICATIONS_HEADERS.PERCENTAGE_CHANGE_FORMATTED,
      width: `95px`,
      sortCallback: (direction) =>
        (activeSort.value = { direction, columnKey: PRICE_IMPLICATIONS_HEADERS.PERCENTAGE_CHANGE_FORMATTED }),
      type: EXCEL_TYPES.ROUNDED_PERCENTAGE,
    },
    {
      header: i18n.t('priceChangeImplications.estimatedYearlyQuantity'),
      key: PRICE_IMPLICATIONS_HEADERS.ESTIMATED_YEARLY_QUANTITY,
      width: `150px`,
      sortCallback: (direction) =>
        (activeSort.value = { direction, columnKey: PRICE_IMPLICATIONS_HEADERS.ESTIMATED_YEARLY_QUANTITY }),
    },
    {
      header: i18n.t('priceChangeImplications.estimatedYearlyImpact'),
      key: PRICE_IMPLICATIONS_HEADERS.ESTIMATED_YEARLY_IMPACT,
      sortCallback: (direction) =>
        (activeSort.value = { direction, columnKey: PRICE_IMPLICATIONS_HEADERS.ESTIMATED_YEARLY_IMPACT }),
      type: EXCEL_TYPES.ROUNDED_CURRENCY,
    },
  ];
};
