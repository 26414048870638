<template>
  <div class="price-delta">
    <div
      class="price-delta-text"
      :class="{ 'positive-delta-text': isPositiveDelta, 'negative-delta-text': !isPositiveDelta }"
    >
      {{ priceText }}
    </div>
    <div class="delta-arrow">
      <ArrowTopRightIcon v-if="isPositiveDelta" />
      <ArrowBottomRightIcon v-if="!isPositiveDelta" />
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { useCurrency } from '@/locale/useCurrency';
import { ArrowTopRightIcon, ArrowBottomRightIcon } from '@/assets/icons/';

export default {
  components: { ArrowTopRightIcon, ArrowBottomRightIcon },
  props: {
    priceDelta: { type: Number, required: true },
    roundingIncrement: { type: Number, required: false, default: 100 },
  },
  setup(props) {
    const { currencyFormat } = useCurrency();

    const root = getCurrentInstance().proxy;
    const { locale } = root.$i18n;

    const priceText = computed(() => {
      const numberFormatter = new Intl.NumberFormat(locale, {
        ...currencyFormat.value,
        maximumFractionDigits: 0,
        roundingIncrement: props.roundingIncrement,
      });
      return numberFormatter.format(props.priceDelta);
    });
    const isPositiveDelta = computed(() => props.priceDelta > 0);
    return {
      priceText,
      isPositiveDelta,
    };
  },
};
</script>

<style scoped lang="scss">
.price-delta {
  display: flex;
  align-items: center;
  .delta-arrow {
    padding-right: 5px;
  }
  .positive-delta-text {
    color: #bb0021;
  }
  .negative-delta-text {
    color: #006a37;
  }
}
</style>
