<template>
  <DashboardCard
    :class="{ 'compact-summary': !isCompactView, 'full-summary': isCompactView }"
    :title="$t('priceChangeImplications.estimatedYearlyImpact')"
    :action-type="isCompactView ? CARD_ACTIONS.REDIRECT : null"
    :loading="loading"
    @on-redirect="moreDetailsClicked"
  >
    <div class="yearly-impacts">
      <div class="positive-and-negative-impacts">
        <YearlyEstimatedImpact
          :title="positiveSumTitle"
          :estimated-amount="summaryData.positiveImplicationsSum"
          class="positive-impacts"
          :is-total="true"
        />
        <YearlyEstimatedImpact
          :title="negativeSumTitle"
          :estimated-amount="summaryData.negativeImplicationsSum"
          :is-total="true"
        />
      </div>
    </div>
  </DashboardCard>
</template>

<script>
import { computed, getCurrentInstance } from 'vue';

import { DashboardCard, CARD_ACTIONS } from '@/modules/core';

import YearlyEstimatedImpact from './YearlyEstimatedImpact';

export default {
  components: {
    YearlyEstimatedImpact,
    DashboardCard,
  },
  props: {
    isCompactView: {
      type: Boolean,
      required: false,
      default: false,
    },
    summaryData: {
      type: Object,
      required: true,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['moreDetailsClick'],
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const totalSumTitle = computed(() =>
      root.$i18n.t('priceChangeImplications.productsTotal', {
        numberOfProducts: props.summaryData.totalNumberOfImplications,
      })
    );
    const positiveSumTitle = computed(() =>
      root.$i18n.t('priceChangeImplications.products.positive', {
        numberOfProducts: props.summaryData.numberOfPositiveImplications,
      })
    );
    const negativeSumTitle = computed(() =>
      root.$i18n.t('priceChangeImplications.products.negative', {
        numberOfProducts: props.summaryData.numberOfNegativeImplications,
      })
    );
    const moreDetailsClicked = () => {
      emit('moreDetailsClick');
    };
    return {
      totalSumTitle,
      positiveSumTitle,
      negativeSumTitle,
      moreDetailsClicked,
      CARD_ACTIONS,
    };
  },
};
</script>

<style scoped lang="scss">
$border-color: #d9dcde;

.compact-summary {
  max-width: 500px;
  height: 214px;
  margin-bottom: 60px;
}

.yearly-impacts {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  position: relative;

  .total-impact {
    flex-grow: 1;
    padding: 0.75rem 0.5rem;
  }
  .positive-and-negative-impacts {
    display: flex;
    align-items: baseline;
    position: absolute;
    bottom: 0;
    .positive-impacts {
      padding-inline-end: 2rem;
    }
  }
}
</style>
