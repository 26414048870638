<template>
  <PageLayout>
    <template #header>
      <h1>{{ $t('priceChangeImplications.header') }}</h1>
      <div class="sub-header">{{ $t('priceChangeImplications.subHeader') }}</div>
    </template>
    <PriceChangeImplicationYearlySummary v-loading="priceChangeImplicationsLoading" :summary-data="summaryData" />
    <div class="table-header">
      <div class="table-title">{{ $t('priceChangeImplications.supplierProductsTitle') }}</div>
      <div>
        <ExportToExcelButton :disable-button="priceChangeImplicationsLoading" @on-click="exportExcel" />
      </div>
    </div>
    <Table
      v-loading="priceChangeImplicationsLoading"
      border
      rounded
      :active-sort="activeSort"
      :data="priceChangeImplicationsTable.data"
      :columns="priceChangeImplicationsTable.columns.value"
      show-index
      @row-click="openProductModal(priceChangeImplicationsTable.data[$event].productId)"
    >
      <template #cell-sku="{ rowData: { sku } }">
        <p :style="{ direction: 'ltr' }">{{ sku }}</p>
      </template>
      <template #cell-priceChange="{ rowData: { priceChange } }">
        <PriceChange
          :from-price="priceChange.fromPrice.netPrice"
          :from-date="priceChange.fromPrice.pricedAt"
          :to-price="priceChange.toPrice.netPrice"
          :to-date="priceChange.toPrice.pricedAt"
          :ltr="$direction == 'ltr'"
        />
      </template>
      <template #cell-estimatedYearlyImpact="{ rowData: { estimatedYearlyImpact } }">
        <PriceDelta :price-delta="estimatedYearlyImpact" />
      </template>
    </Table>
  </PageLayout>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { DateTime } from 'luxon';

import { PageLayout, Table } from '@/modules/core/components';
import { useTenancy } from '@/modules/auth';
import { openProductModal } from '@/modules/products/store/product-modal';

import PriceChange from './PriceChange';
import PriceDelta from './PriceDelta';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';
import { usePriceChangeImplications } from '../../compositions/priceChangeImplications/priceChangeImplications';
import PriceChangeImplicationYearlySummary from './PriceChangeImplicationYearlySummary';
import { PRICE_IMPLICATIONS_HEADERS } from './priceChangeImplicationsConstants';
import { exportPriceImplications } from './priceChangeImplicationsExcel';
import getColumns from './priceChangeImplicationsTableColumns';
import ExportToExcelButton from '../ExportToExcelButton';

const getPriceImplicationsCompare = (direction, columnKey) => {
  return (a, b) => {
    let parsedColumnKey = columnKey;
    if (columnKey == PRICE_IMPLICATIONS_HEADERS.PERCENTAGE_CHANGE_FORMATTED) {
      parsedColumnKey = 'percentageChange';
    }
    const implication1 = direction == 1 ? a[parsedColumnKey] : b[parsedColumnKey];
    const implication2 = direction == 1 ? b[parsedColumnKey] : a[parsedColumnKey];
    if (typeof implication1 == 'string') {
      return implication1.localeCompare(implication2);
    }
    return implication1 - implication2;
  };
};

export default {
  components: {
    PageLayout,
    Table,
    PriceChange,
    PriceDelta,
    PriceChangeImplicationYearlySummary,
    ExportToExcelButton,
  },
  setup() {
    const root = getCurrentInstance().proxy;
    const { locale } = root.$i18n;
    const { currentTenant } = useTenancy();
    const customerId = computed(() => currentTenant.value.id);
    const toDate = DateTime.local();
    const fromDate = toDate.minus({ days: 90 });
    const filterVolatility = root.$route.query.filterVolatility;
    const useCache = root.$route.query.useCache;
    const filterMoreThenOneInstancePerOrder = root.$route.query.filterMoreThenOneInstancePerOrder;
    const {
      loading: priceChangeImplicationsLoading,
      priceChangeImplications,
      summaryData,
    } = usePriceChangeImplications({
      customerId,
      fromDate: fromDate.toISODate(),
      toDate: toDate.toISODate(),
      useCache,
      filterVolatility,
      filterMoreThenOneInstancePerOrder,
    });

    let activeSort = ref({ direction: -1, columnKey: PRICE_IMPLICATIONS_HEADERS.ESTIMATED_YEARLY_IMPACT });
    const formattedPriceChangeImplications = computed(() => {
      return priceChangeImplications.value
        ?.map((implication) => {
          const percentageChangeFormatted = implication.percentageChange
            ? `${Math.round(implication.percentageChange).toLocaleString(locale)}%`
            : MISSING_DATA_TEXT;
          return {
            ...implication,
            percentageChangeFormatted,
            priceChange: {
              toPrice: implication.latestPriceLevel.pricedAtPoint,
              fromPrice: implication.comparedToPriceLevel.pricedAtPoint,
            },
            productName: implication.product.name,
            productSku: implication.product.sku,
            productSupplierName: implication.product.business.name,
            estimatedYearlyQuantity: Math.round(implication.estimatedYearlyQuantity),
          };
        })
        .sort(getPriceImplicationsCompare(activeSort.value.direction, activeSort.value.columnKey));
    });
    const priceChangeImplicationsTable = computed(() => ({
      data: formattedPriceChangeImplications.value,
      activeSort: ref({ direction: 1, columnKey: PRICE_IMPLICATIONS_HEADERS.NAME }),
      columns: computed(() => getColumns(activeSort)),
    }));

    const exportExcel = () => {
      exportPriceImplications(
        priceChangeImplicationsTable.value.data,
        priceChangeImplicationsTable.value.columns.value
      );
    };

    return {
      priceChangeImplicationsTable,
      priceChangeImplicationsLoading,
      activeSort,
      summaryData,
      openProductModal,
      exportExcel,
    };
  },
};
</script>

<style scoped lang="scss">
.sub-header {
  font-size: 14px;
  font-weight: 500;
}

.table-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  .table-title {
    font-size: 1rem;
    font-weight: bold;
    padding-bottom: 15px;
  }

  .table-button {
    padding: 0.3rem;
    margin: 0;
  }
}
</style>
