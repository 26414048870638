import i18n from '@/imports/startup/client/i18n';
import { DateTime } from 'luxon';

import { exportToExcel } from '@/imports/ui/file_exports';
import { EXCEL_TYPES, buildColumnsByTableViewForExcel } from '../../excel';
import { PRICE_IMPLICATIONS_HEADERS } from './priceChangeImplicationsConstants';

export const exportPriceImplications = (priceImplications, tableColumns) => {
  const updatedTableColumns = [];

  tableColumns.forEach((column) => {
    if (column.key === PRICE_IMPLICATIONS_HEADERS.PRICE_CHANGE) {
      const columnsToInsertInsteadOfPriceChange = [
        {
          key: 'priceBeforeChange',
          header: i18n.t('terms.supplierTerms.supplierTermTable.excel.extraHeaders.priceBeforeChange'),
          type: EXCEL_TYPES.CURRENCY,
        },
        {
          key: 'dateBeforeChange',
          header: i18n.t('terms.supplierTerms.supplierTermTable.excel.extraHeaders.dateBeforeChange'),
          type: EXCEL_TYPES.DATE,
        },
        {
          key: 'priceAfterChange',
          header: i18n.t('terms.supplierTerms.supplierTermTable.excel.extraHeaders.priceAfterChange'),
          type: EXCEL_TYPES.CURRENCY,
        },
        {
          key: 'dateAfterChange',
          header: i18n.t('terms.supplierTerms.supplierTermTable.excel.extraHeaders.dateAfterChange'),
          type: EXCEL_TYPES.DATE,
        },
      ];
      updatedTableColumns.push(...columnsToInsertInsteadOfPriceChange);
    } else {
      updatedTableColumns.push(column);
    }
  });

  const columns = buildColumnsByTableViewForExcel({ tableColumns: updatedTableColumns });

  const metadata = {
    filename: i18n.t('terms.supplierTerms.supplierTermTable.excel.fileNames.priceChangeImplications'),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.excel.sheetname'),
    direction: i18n.t('direction'),
    columns,
  };

  const data = priceImplications.map(
    ({
      product: {
        sku: productSku,
        name: productName,
        business: { name: productSupplierName },
      },
      comparedToPriceLevel: {
        pricedAtPoint: { netPrice: priceBeforeChange, pricedAt: dateBeforeChange },
      },
      latestPriceLevel: {
        pricedAtPoint: { netPrice: priceAfterChange, pricedAt: dateAfterChange },
      },
      percentageChange,
      ...rest
    }) => {
      const estimatedYearlyImpact = Math.round(rest.estimatedYearlyImpact / 100) * 100;

      return {
        ...rest,
        productSku,
        productName,
        productSupplierName,
        priceBeforeChange,
        priceAfterChange,
        dateBeforeChange: DateTime.fromISO(dateBeforeChange).toJSDate(),
        dateAfterChange: DateTime.fromISO(dateAfterChange).toJSDate(),
        percentageChangeFormatted: Math.round(percentageChange) / 100,
        estimatedYearlyImpact,
      };
    }
  );

  exportToExcel(metadata, data);
};
